import React from 'react';
import './style.scss';
import title from '../../Constants/title';

function Footer() {
  return (
    <div className="footer__copyright text-black dark:text-white">
      Copyright © 2025{' '}
      <a href={title.url} alt={title.name}>
        {title.name}
      </a>
      . All rights reserved.
    </div>
  );
}

export default Footer;
