const concord = {
    name: "ConcordProsper",
    logo: false,
    landing: 'https://landing.concordprosper.com',
    landing: 'https://concordprosper.com'

}

const finbright = {
    name: "FinBright",
    logo: true,
    landing: 'https://landing.finbright.org',
    url: 'https://finbright.org'
}

export default finbright